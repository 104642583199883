import React from "react";
import { Link } from "gatsby";

export const createLocalLink = url => {
  if (!url || !url.includes(process.env.WORDPRESS)) {
    return url;
  }
  return url.replace(process.env.WORDPRESS, ``);
};

// gatsby-wpgraphql-inline-images options for find & replace
export const inlineUrls = {
  wordPressUrl: process.env.GATSBY_WORDPRESS_URL,
  uploadsUrl: process.env.GATSBY_WORDPRESS_UPLOADS
};

// Local links
export const LocalLinks = ({ children, title, url }) => {
  if (!url || !url.includes(process.env.WORDPRESS)) {
    return (
      <a href={url} title={title} name={title} rel="noreferrer">
        {children}
      </a>
    );
  }
  return (
    <Link to={createLocalLink(url)} title={title} name={title}>
      {children}
    </Link>
  );
};
