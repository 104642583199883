import { graphql, StaticQuery } from "gatsby";
import React from "react";
import FooterMenu from "../Menu/footer";

class Footer extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            wordPress {
              allSettings {
                generalSettingsTitle
              }
            }
          }
        `}
        render={(data) => (
          <section id="footer">
            <div className="container-full">
              <div className="row">
                <div className="col-md-6">
                  
                </div>
                <div className="col-md-6" id="copyright">
                  <FooterMenu location={this.props.location} />
                </div>
              </div>
            </div>
          </section>
        )}
      />
    );
  }
}

export default Footer;
