import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import SiteMenu from "../Menu";

class Header extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            wordPress {
              allSettings {
                generalSettingsTitle
              }
            }
          }
        `}
        render={(data) => (
          <header>
            <nav className="navbar">
              <Link className="navbar-brand" to="/">
                <img
                  src={"/svg/balcony8.svg"}
                  width="80"
                  alt="Balcony8 Brand Buru"
                />
              </Link>
              <SiteMenu location={this.props.location} />
            </nav>
          </header>
        )}
      />
    );
  }
}

export default Header;
