import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = ({ children, location, cssClass }) => {
  return (
    <>
      <Header location={location} />
      <div className="row no-gutters">
        <div className="col-md-12">
          <main className={cssClass}>{children}</main>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
