import React, { useState } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { createLocalLink } from "../../utils";
import parse from "html-react-parser";

const FOOTER_MENU_QUERY = graphql`
  fragment FooterMenuFields on WordPress_MenuItem {
    id
    menuItemId
    label
    url
    cssClasses
    target
    connectedObject {
      __typename
    }
  }

  query GET_FOOTER_MENU_ITEMS {
    wordPress {
      menuItems(where: { location: SOCIAL }) {
        nodes {
          ...FooterMenuFields
        }
      }
    }
  }
`;

const RenderMenuItem = (props) => {
  const { menuItem, location } = props;
  const link = createLocalLink(menuItem.url);
  const path = location && location !== undefined ? location.pathname : "";
  let classes = menuClasses(menuItem, path, link);
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (e) => {
    if (e.currentTarget.classList.contains("active")) {
      setActiveLink("");
    } else {
      setActiveLink(e.currentTarget.dataset.name);
    }
  };

  if (menuItem.cssClasses) {
    menuItem.cssClasses.map((q) => classes.push(q));
  }

  return (
    <li className={classes.join(" ")} key={menuItem.id}>
      {link && (link.includes("#") || link.includes("http")) ? (
        <a
          className={`nav-link ${
            activeLink === menuItem.label ? "active" : null
          }`}
          onClick={(e) => handleClick(e)}
          href={link}
          target={menuItem.target ? menuItem.target : "_self"}
          data-name={menuItem.label}
          rel="noopener noreferrer"
        >
          {parse(menuItem.label)}
        </a>
      ) : (
        <Link className="nav-link" to={link} activeClassName="active">
          {parse(menuItem.label)}
        </Link>
      )}
      {renderSubMenu(menuItem, location)}
    </li>
  );
};

const renderSubMenu = (menuItem, location) => {
  if (menuItem.childItems && menuItem.childItems.nodes.length) {
    return (
      <ul className="dropdown-menu">
        {menuItem.childItems.nodes.map((item, index) => {
          // console.log(item);
          return (
            <RenderMenuItem key={index} menuItem={item} location={location} />
          );
        })}
      </ul>
    );
  }
};

const menuClasses = (menuItem, path, link) => {
  let classes = ["menu-item"];
  classes.push("menu-item-" + menuItem.menuItemId);
  if (menuItem.childItems && menuItem.childItems.nodes.length) {
    classes.push("dropdown");
    classes.push("dropdown-toggle");
    classes.push("menu-item-has-children");
  }
  if (link === path) {
    classes.push("active");
  }
  return classes;
};

class FooterMenu extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.state = {
      isActive: false,
      activeLink: "",
    };
  }

  handleClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  render() {
    return (
      <StaticQuery
        query={FOOTER_MENU_QUERY}
        render={(data) => {
          if (data.wordPress.menuItems) {
            return (
              <>
                <ul id="menu-social" className="social-menu">
                  {data.wordPress.menuItems.nodes.map((menuItem, index) => {
                    return (
                      <RenderMenuItem
                        key={index}
                        menuItem={menuItem}
                        location={this.props.location}
                      />
                    );
                  })}
                </ul>
              </>
            );
          } else {
            return null;
          }
        }}
      />
    );
  }
}

export default FooterMenu;
